<script>
import t1 from "@/assets/common/boost.png"
import t2 from "@/assets/common/host.png"
import t3 from "@/assets/common/cashback.png"
import t4 from "@/assets/common/levelup.png"
import t5 from "@/assets/common/bespoke.png"
import s1 from "@/assets/common/stat3.svg"
import s2 from "@/assets/common/stat1.svg"
import s3 from "@/assets/common/stat2.svg"
import s4 from "@/assets/common/stat4.svg"
import MainTabBar from "@/components/tabbar/MainTabBar.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import FootC from "@/components/FootC.vue";
import members from "./members.vue";

export default {
    name: "vip",
    components: {HeaderNav, MainTabBar, FootC},
    data() {
        return {
            level: 0,
            integral: '',
            integralItem: '',
            progress: 0,
            svgList: [s1, s2, s3, s4, s1, s2, s3, s4, s1, s2],
            integralList: [
                {t: '1USDT', i: 1},
                {t: '7.2CNY', i: 1},
                {t: '25400VND', i: 1},
                {t: '15900IDR', i: 1},
                {t: '34THB', i: 1},
                {t: '155JPY', i: 1},
                {t: '1400KRW', i: 1},
            ],
            txtList: [
                {
                    img: t1,
                    title: '助推奖金',
                    content: '您可以预期每周和每月根据您的游戏量获得奖金。玩得越多，获得的奖金就越多。'
                },
                {
                    img: t2,
                    title: '专属 VIP 服务代表',
                    content: '被分配一名将支持并满足您的投注需求的专属 VIP 服务代表。'
                },
                {
                    img: t3,
                    title: '近期游戏表现奖金',
                    content: '运气不佳？Mago 会在您每次升级时根据您的损失提供额外资金。'
                },
                {
                    img: t4,
                    title: '升级奖金',
                    content: '在每达到下个级别后获取奖金。所达级别越高，升级奖金就越大。'
                },
                {
                    img: t5,
                    title: 'Bespoke',
                    content: '与您的专属 VIP 服务代表合作，根据您的投注需求定制福利。'
                },
            ],
            starList: [
                {
                    p1: 'VIP1',
                    p2: '1K',
                    p4: '升级积分',
                    p5: '电子反水：0.6%',
                    p6: '视讯反水：0.4%',
                    p7: '体育反水：0.38%',
                    img: require('../assets/common/vip1.png'),
                },
                {
                    p1: 'VIP2',
                    p2: '10k',
                    p4: '升级积分',
                    p5: '电子反水：0.65%',
                    p6: '视讯反水：0.48%',
                    p7: '体育反水：0.38%',
                    img: require('../assets/common/vip2.png'),
                },
                {
                    p1: 'VIP3',
                    p2: '50k',
                    p4: '升级积分',
                    p5: '电子反水：0.7%',
                    p6: '视讯反水：0.52%',
                    p7: '体育反水：0.4%',
                    img: require('../assets/common/vip3.png'),
                },
                {
                    p1: 'VIP4',
                    p2: '100k',
                    p4: '升级积分',
                    p5: '电子反水：0.75%',
                    p6: '视讯反水：0.58%',
                    p7: '体育反水：0.45%',
                    img: require('../assets/common/vip4.png'),
                },
                {
                    p1: 'VIP5',
                    p2: '300k',
                    p4: '升级积分',
                    p5: '电子反水：0.8%',
                    p6: '视讯反水：0.62%',
                    p7: '体育反水：0.45%',
                    img: require('../assets/common/vip5.png'),
                },
                {
                    p1: 'VIP6',
                    p2: '800k',
                    p4: '升级积分',
                    p5: '电子反水：0.85%',
                    p6: '视讯反水：0.68%',
                    p7: '体育反水：0.5%',
                    img: require('../assets/common/vip6.png'),
                },
                {
                    p1: 'VIP7',
                    p2: '1500k',
                    p4: '升级积分',
                    p5: '电子反水：0.9%',
                    p6: '视讯反水：0.78%',
                    p7: '体育反水：0.55%',
                    img: require('../assets/common/vip7.png'),
                },
                {
                    p1: 'VIP8',
                    p2: '5000k',
                    p4: '升级积分',
                    p5: '电子反水：0.95%',
                    p6: '视讯反水：0.88%',
                    p7: '体育反水：0.68%',
                    img: require('../assets/common/vip8.png'),
                },
                {
                    p1: 'VIP9',
                    p2: '10000k',
                    p4: '升级积分',
                    p5: '电子反水：1%',
                    p6: '视讯反水：0.9%',
                    p7: '体育反水：0.8%',
                    img: require('../assets/common/vip9.png'),
                },
                {
                    p1: 'VIP10',
                    p2: '30000k',
                    p4: '升级积分',
                    p5: '电子反水：1.2%',
                    p6: '视讯反水：1%',
                    p7: '体育反水：1%',
                    img: require('../assets/common/vip10.png'),
                },
            ]

        }
    },
    methods: {
        transaction() {
            this.$router.push("/exchange");
        },
        register() {
            this.$store.commit("showRegisterFn", true)
        },
        getIntegral() {
            this.$http.get(this.host + '/user-integral/getCcyRebate').then(response => {
                let resp = response.body
                let rank = [], integral = Number(this.integral)
                if (resp.code === '0000') {
                    if (integral > Number(rank[rank.length - 1])) {  //满级
                        this.progress = 100
                        return
                    }

                    if (resp.data[0] && resp.data[0].rebate) {
                        rank = resp.data[0].rebate
                        rank = JSON.parse(rank)
                        let flag = false
                        if (integral < Number(rank[0])) {  //0级
                            this.progress = Number(((integral / Number(rank[0])) * 100).toFixed(2))
                            return
                        }
                        rank.forEach((item, i) => {
                            if (flag) {
                                return
                            } else {
                                if (integral < Number(item)) {
                                    flag = true
                                }
                            }
                        })
                    }
                    let prog = Number(rank[Number(this.level + 1)]) - Number(rank[Number(this.level)])
                    let cur = integral - Number(rank[Number(this.level - 1)])
                    this.progress = Number(((cur / prog) * 100).toFixed(2))
                }
            });
        }
    },
    computed: {
        members() {
            return members
        },
        user() {
            return this.$store.state.member
        }
    },
    created() {
        if (this.user && this.user !== 'null') {
            this.$http.get(this.host + '/user-integral/getIntegralAccount').then(response => {
                let resp = response.body
                if (resp.data) {  //没数据  都为0
                    this.integral = resp.data.betAll
                    this.integralItem = resp.data.integral
                    this.level = Number(resp.data.vipLevel)
                    if (this.level === 10) {
                        this.progress = 100
                    } else {
                        this.getIntegral()
                    }
                }
            });
        }
    }
}
</script>

<template>
    <div class="vip">
        <div class="box222">
            <HeaderNav></HeaderNav>
        </div>
        <template v-if="user">
            <div class="card">
                <div style="display: flex;justify-content: space-between;">
                    <span>ID:{{ user && user.userNo }}</span>
                </div>
                <div style="display: flex;justify-content: space-between">
                    <div>积分：{{ integralItem }}</div>
                    <Button class="integral-btn" size="small" @click="transaction">{{ $t("common.exchange") }}
                    </Button>
                </div>
                <div class="t1">
                    <span>{{ $t('new.yourVIPProgress') }}</span>
                    <span>{{ progress }}%</span>
                </div>
                <div class="t2">
                    <div>
                        <Progress :hide-info="true" :percent="progress"/>
                    </div>
                    <p class="t2-1">
                        <span>{{ $t('new.currentLevel') }}</span>
                        <span>V{{ level }}</span>
                    </p>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="rank">
                <div class="rank-title rank-title-top">
                    {{ $t('new.exclusiveVIPExperience') }}
                </div>
                <div class="integral integral-top">
                    {{ $t('new.unlockExclusiveBenefits') }}
                    <div class="btn" @click="register">
                        {{ $t('new.register') }}
                    </div>
                </div>
            </div>
        </template>
        <div class="rank">
            <div class="rank-title">
                {{ $t('new.vipRankingSystem') }}
            </div>
            <div class="welfare">
                <div class="welfare-con">
                    <div class="top">
                        <div v-for="(item,i) in svgList" class="svgL" :key="i">
                            <img class="star-svg" :src="item" alt="">
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="card-list">
                        <div class="card-item" v-for="(item,i) in starList" :key="i">
                            <!--                            <p class="p1">{{ item.p1 }}</p>-->
                            <p class="p1"><img :src="item.img" alt=""></p>
                            <p class="p2">{{ item.p2 }}</p>
                            <p class="p4">{{ item.p4 }}</p>
                            <p class="p5" style="padding-top: 10px">{{ item.p5 }}</p>
                            <p class="p6">{{ item.p6 }}</p>
                            <p class="p7">{{ item.p7 }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="integral">
            <div class="rank">
                <div class="rank-title">
                    Mago {{ $t('new.pointsSystem') }}
                </div>
            </div>
            <div class="title"> 积分比例 </div>
            <div class="table">
                <div class="table-item" v-for="(item ,i ) in integralList" :key="i">
                    <div>{{ item.t }}</div>
                    <div>{{ item.i }}积分</div>
                </div>
            </div>
        </div>
        <div class="rank">
            <div class="rank-title">
                {{ $t('new.vipClubBenefits') }}
            </div>
            <div class="wrapper" v-for="(item,k)  in txtList" :key="k">
                <div><img class="t-img" :src="item.img" alt=""></div>
                <div>
                    <p class="t-txt">{{ item.title }}</p>
                    <p class="c-txt">{{ item.content }}</p>
                </div>
            </div>
        </div>
        <FootC></FootC>
        <Main-tab-bar></Main-tab-bar>
    </div>
</template>

<style scoped lang="scss">
.vip {
    display: flex;
    flex-direction: column;
    color: #fff;
    font-weight: 500;
    background-color: #1A2C38;
    padding: 60px 0 68px 0;

    .card {
        border-radius: 5px;
        width: 90%;
        margin: 10px 5%;
        padding: 20px 10%;
        height: 206px;
        border: 2px solid rgb(47, 69, 83);

        .integral-btn {
            background-color: #1375E1;
            border: none;
            float: right;
            color: #fff;
            //margin-top: 6px;
        }

        .t1 {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
        }

        .t2 {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .t2-1 {
                margin-top: 6px;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .rank {
        margin-top: 20px;
        background-color: #1A2C38;

        .rank-title {
            text-align: center;
            padding: 20px;
            font-size: 20px;
            font-weight: 800;
        }

        .rank-title-top {
            font-size: 28px;
            font-weight: 900;
            text-align: left;
            background-color: #213743;
        }

        .welfare {
            height: 300px;
            width: 96%;
            margin: auto;
            overflow-x: scroll;

            .welfare-con {
                width: 625%;
                height: 100%;
                display: flex;
                flex-direction: column;

                .top {
                    padding: 20px;
                    display: flex;
                    justify-content: space-around;

                    .star-svg {
                        width: 30px;
                    }

                    .svgL {
                        width: 24%;
                        display: flex;
                        align-items: center;

                        .line {
                            flex: 1;
                            height: 2px;
                            background-color: #233743;
                            //width: 100px;
                        }
                    }

                }

                .card-list {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;

                    .card-item {
                        height: 100%;
                        width: 9.5%;
                        display: flex;
                        background-color: #233743;
                        flex-direction: column;
                        padding: 20px;

                        .p1 {
                            //background-color: #C59C6C;
                            padding: 2px 4px;
                            border-radius: 4px;
                            width: 70px;
                            text-align: center;
                            color: #000;
                            font-size: 12px;

                            & > img {
                                width: 60px;
                            }
                        }

                        .p2 {
                            font-size: 28px;
                            font-weight: 800;
                        }

                        .p3 {
                            color: #b1bad3;
                        }

                        .p4 {
                            margin-top: 0px;
                        }
                    }

                    .card-item:nth-child(2) {
                        .p1 {
                            //background-color: #B2CCCC;
                        }
                    }

                    .card-item:nth-child(3) {
                        .p1 {
                            //background-color: #FFD100;
                        }
                    }

                    .card-item:nth-child(4) {
                        .p1 {
                            width: 100px;
                            //background-color: #6FDDE7;
                        }
                    }
                }
            }
        }

        .wrapper {
            padding: 0 10px;
            width: 94%;
            height: 100px;
            margin: 10px auto;
            display: flex;
            border-radius: 3px;
            background-color: #213743;
            align-items: center;

            .t-img {
                margin-right: 10px;
            }

            .t-txt {
                font-size: 16px;
                font-weight: 800;
            }

            .c-txt {
                font-size: 12px;
                color: #BAB1D3;
            }
        }
    }

    .integral {
        width: 90%;
        margin-left: 5%;

        .table-item {
            display: flex;
            height: 30px;
            line-height: 30px;

            & > div {
                width: 50%;
                text-align: center;
                border: 1px solid #ccc;
                border-bottom: none;
            }

            & > div:nth-child(1) {
                border-right: none;
            }
        }

        .table-item:last-child {
            & > div {
                border: 1px solid #ccc;
            }
        }
    }

    .integral-top {
        margin-left: 0;
        padding: 0 20px 20px;
        color: #BAB1D3;
        font-size: 16px;
        background-color: #213743;
        width: 100%;

        .btn {
            width: 68px;
            height: 44px;
            text-align: center;
            line-height: 44px;
            color: #fff;
            margin: 30px 0;
            background-color: #1375E1;
            border-radius: 4px;
        }
    }
}
</style>
